import React from "react";
import DemoCeo from "../components/molecules/DemoCeo/DemoCeo";
import ManualsSection from "../components/molecules/ManualsSection/ManualsSection";
import Quotes from "../components/molecules/Quotes/Quotes";
import VideoBanner from "../components/molecules/VideoBanner/VideoBanner";
import DemoSection from "../components/ogranisms/DemoSection/DemoSection";
import * as styles from "./styles.module.scss";
import PdfSection from "../components/atoms/PdfSection/PdfSection";
import PresentationsContainer from "../components/ogranisms/PresentationsContainer/PresentationsContainer";
import ArticlesContainer from "../components/molecules/ArticlesContainer/ArticlesContainer";
import VideoTabs from "../components/ogranisms/VideosTabs/VideosTabs";
import DemoSectionX from "../components/molecules/DemoSectionX/DemoSectionX";
import GetWiZRHeader from "../components/atoms/Getwizrheader";
import Getwizrfooter from "../components/atoms/GetWizrFooter/Getwizrfooter";
import PoweredBanner from "../components/molecules/PoweredBanner/PoweredBanner";
function PoweredDemo() {
  const data2 = [
    {
      quote: "“We have only seen the beginning of videos and virtual presence”",
    },
    {
      quote:
        "“The ultimate workspace must be an all-in-one solution, solving most of your daily communications”",
    },
    {
      quote:
        "“Only by making something delightfully good you change habits and preferences.“",
    },
  ];
  return (
    <>
      <GetWiZRHeader nolinks={true}/>
      <PoweredBanner />
      <p className={styles.topPowered}>
      Explore new ways to "Express Yourself" and to "Shine Professionally". <br/> Here we show how it all comes together through <br/> manuals, videos, presentations and articles - please enjoy. 
      </p>
      <DemoSectionX />
      <DemoSection />
      <ManualsSection slicenumber={4} />
      <VideoTabs />
      <Quotes data={data2} />
      <DemoCeo />
      <PdfSection title="III. PRESENTATIONS"/>
      <PresentationsContainer />
      <PdfSection extraClass="brightversion" title="IV. ARTICLES" />
      <ArticlesContainer />
      <h1 className="title-demo-section">
      Better structure - Impactful presentations - Innovative sharing
      </h1>
      <VideoBanner />
      <Getwizrfooter />
    </>
  );
}
export default PoweredDemo;
