// extracted by mini-css-extract-plugin
export var active = "ArticlesContainer-module--active--6fe2a";
export var arrow = "ArticlesContainer-module--arrow--3aa38";
export var container = "ArticlesContainer-module--container--cec59";
export var fullSection2 = "ArticlesContainer-module--fullSection2--f93fb";
export var left = "ArticlesContainer-module--left--98f25";
export var navigate = "ArticlesContainer-module--navigate--f8264";
export var right = "ArticlesContainer-module--right--18cec";
export var slider = "ArticlesContainer-module--slider--27f11";
export var tabs = "ArticlesContainer-module--tabs--aea94";
export var toggle = "ArticlesContainer-module--toggle--3f416";