import image1 from "../images/mwservice-01.jpg";
import image2 from "../images/mwservice-02.jpg";
import image3 from "../images/mwservice-03.jpg";
import image4 from "../images/mwservice-04.jpg";
import image5 from "../images/mwservice-05.jpg";
import image6 from "../images/mwservice-06.jpg";
import image7 from "../images/mwservice-07.jpg";
import image8 from "../images/mwservice-08.jpg";
import image9 from "../images/mwservice-09.jpg";
import image10 from "../images/mwservice-10.jpg";
import image11 from "../images/mwservice-11.jpg";
import image12 from "../images/mwservice-12.jpg";



import imagepres2 from "../images/intro-1.jpg";
import image22 from "../images/intro-2.jpg";
import image33 from "../images/intro-3.jpg";
import image44 from "../images/intro-4.jpg";
import image55 from "../images/intro-5.jpg";
import image66 from "../images/intro-6.jpg";
import image77 from "../images/intro-7.jpg";


import sales1 from "../images/sales1.png";
import sales2 from "../images/sales2.png";
import sales3 from "../images/sales3.png";
import sales4 from "../images/sales4.png";
import sales5 from "../images/sales5.png";
import sales6 from "../images/sales6.png";
import sales7 from "../images/sales7.png";
import sales8 from "../images/sales8.png";
import sales9 from "../images/sales9.png";
import sales10 from "../images/sales10.png";
import sales11 from "../images/sales11.png";
import sales12 from "../images/sales12.png";
import sales13 from "../images/sales13.png";
import sales14 from "../images/sales14.png";
import sales15 from "../images/sales15.png";
import sales16 from "../images/sales16.png";
import sales17 from "../images/sales17.png";
import sales18 from "../images/sales18.png";
import sales19 from "../images/sales19.webp";



export const presentation1 = [
    sales1,sales2,sales3,sales4,sales5,sales6,sales7,sales8,sales9,sales10,sales11,sales12,sales13,sales14,sales15,sales16,sales17,sales18,sales19
]


export const presentation3 = [
    image1,image2,image3,image4,image5,image6,image7,image8,image9,image10,image11,image12
]

export const presentation2 = [
imagepres2,image22,image33,image44,image55,image66,image77
]