import React, { useState } from "react";
import * as styles from "./ArticlesContainer.module.scss";
import "../../../styles/slick-theme.scss";
import "../../../styles/slick.scss";
import FullScreen from "../../../svg/fullscreen.svg";
import ExitFullScreen from "../../../svg/exitfullscreen.svg";
import PresSlider from "../PresSlider/PresSlider";
import PresSoftware from "../PresSlider/PresSoftware";
import PresService from "../PresSlider/PresService";

function ArticlesContainer() {
  const [tab, setTab] = useState("pres1");

  const [state2, setState2] = useState(false);
  const toggleFullScreen = () => {
    if (typeof document !== "undefined") {
      if (state2) {
        document?.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
      setState2(!state2);
    }
  };

  // useEffect(() => {
  //   if (typeof document !== "undefined") {
  //     const handleFullScreenChange = () => {
  //       setState2(document.fullscreen);
  //     };
  //     document.addEventListener("fullscreenchange", handleFullScreenChange);
  //     return () => {
  //       document.removeEventListener(
  //         "fullscreenchange",
  //         handleFullScreenChange
  //       );
  //     };
  //   }
  // }, []);

  return (
    <div
      className={
        state2
          ? `${styles.container} presentationsSlider ${styles.fullSection2}`
          : `${styles.container} presentationsSlider`
      }
    >
      <div className={styles.tabs}>
        <button
          className={tab === "pres1" ? styles.active : ``}
          onClick={() => {
            setTab("pres1");
          }}
        >
          Our Growth Journey
        </button>
        <button
          className={tab === "pres2" ? styles.active : ``}
          onClick={() => {
            setTab("pres2");
          }}
        >
          About WiZR Connect
        </button>
        <button
          className={tab === "pres3" ? styles.active : ``}
          onClick={() => {
            setTab("pres3");
          }}
        >
         
          Get WiZR
        </button>
        <button className={styles.toggle} onClick={toggleFullScreen}>
          {state2 ? <ExitFullScreen /> : <FullScreen />}
        </button>
      </div>
      {tab === "pres3" ? (
        <PresService state={state2} extraClass={state2 ? `fullslider2` : ``} />
      ) : tab === "pres2" ? (
        <PresSoftware state={state2} extraClass={state2 ? `fullslider2` : ``} />
      ) : tab === "pres1" ? (
        <PresSlider state={state2} extraClass={state2 ? `fullslider2` : ``} />
      ) : (
        ""
      )}
    </div>
  );
}
export default ArticlesContainer;
