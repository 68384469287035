import React from "react";
import * as styles from "./DemoSection.module.scss";
import { IoIosArrowForward } from "react-icons/io";
import StyledButton from "../../atoms/StyledButton";

function DemoSection() {
  return (
    <div className={styles.manuals}>
      <div className={styles.top}>
        <h1>How to get started</h1>
        <div className={styles.list}>
        <p>A. <span>Visual Tour</span> – Scroll at your own pace (see section 1 below)</p>
        <p>B. <span>Multimedia</span> – See video intros, presentations and articles (section 2-4)   </p>
        <p>C. <span>Applied DEMO</span> – How material transforms</p>
        <p>D. <span>Trial</span> – Test your material, allow us to help you</p>
        </div>
        <div className={styles.btn}>
        <StyledButton
          link="mailto:sales@masterwizr.com"
          title="Book Demo or Trial"
          icon={<IoIosArrowForward />}
          extraClass="purplebtn"
          email={true}
        />
        </div>
      </div>
      {/* <div className={styles.main}>
        <div className={styles.container}>
          {data.slice(0,slicenumber).map((item, i) => {
            return (
              <Link to={item.link}>
                <div key={i} className={styles.item}>
                  <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${item.image})` }}
                  />
                  <h2>{item.title}</h2>
                </div>
              </Link>
            );
          })}
        </div>
      </div> */}
    </div>
  );
}
export default DemoSection;
