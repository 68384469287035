import * as styles from "./PresSlider.module.scss";
import React, { useState } from "react";
import Slider from "react-slick";
import ArrowRight from "../../../svg/icon-arrow-right.svg";
import ArrowLeft from "../../../svg/icon-arrow-left.svg";
import { presentation2} from "../../../constants/presentationsdata";

function PresSoftware({extraClass,state}){
    const [activeSlide, setActiveSlide] = useState(0);
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow:<div><ArrowLeft/></div>,
        nextArrow:<div><ArrowRight /></div>,
        fade: true,
        accessibility: true,
        fade: false,
        accessibility: true,
        speed:0,
        afterChange: current => setActiveSlide(current)
      };
    return(
        <div  className={`${styles.slider} ${styles[extraClass]}`}>
        <Slider  focusOnSelect={true} {...settings}>
          {presentation2.map((item) => {
            return <img alt="pres" src={item} />;
          })}
        </Slider>
        <div className={styles.btns}>
          <p className={styles.text}>{activeSlide + 1} / {presentation2?.length} </p>
          {!state && <a aria-label="redirect" download className={styles.btn}>
            Download as PDF
          </a>}
        </div>
      </div>
    );
}
export default PresSoftware;