import React, { useState } from "react";
import * as styles from "./PresentationsContainer.module.scss";
import "../../../styles/slick-theme.scss";
import "../../../styles/slick.scss";
import FullScreen from "../../../svg/fullscreen.svg";
import ExitFullScreen from "../../../svg/exitfullscreen.svg";
import PresSlider from "../../atoms/PresSlider/PresSlider";
import PresSoftware from "../../atoms/PresSlider/PresSoftware";
import PresService from "../../atoms/PresSlider/PresService";
import Visualization from "../../atoms/PresSlider/Visualization";

function PresentationsContainer() {
  const [tab, setTab] = useState("pres1");

  const [state, setState] = useState(false);
  const toggleFullScreen = () => {
    if (typeof document !== "undefined") {
      if (state) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
      setState(!state);
    }
  };

  return (
    <div
      className={
        state
          ? `${styles.container} presentationsSlider ${styles.fullSection}`
          : `${styles.container} presentationsSlider`
      }
    >
      <div className={styles.tabs}>
        <button
          className={tab === "pres1" ? styles.active : ``}
          onClick={() => {
            setTab("pres1");
          }}
        >
          Essentials
        </button>
        <button
          className={tab === "pres2" ? styles.active : ``}
          onClick={() => {
            setTab("pres2");
          }}
        >
          Software intro
        </button>
        <button
          className={tab === "pres3" ? styles.active : ``}
          onClick={() => {
            setTab("pres3");
          }}
        >
          Services intro
        </button>
        <button
          className={tab === "pres4" ? styles.active : ``}
          onClick={() => {
            setTab("pres4");
          }}
        >
          Eco System
        </button>
        <button className={styles.toggle} onClick={toggleFullScreen}>
          {state ? <ExitFullScreen /> : <FullScreen />}
        </button>
      </div>
      {tab === "pres3" ? (
        <PresService state={state} extraClass={state ? `fullslider` : ``} />
      ) : tab === "pres2" ? (
        <PresSoftware state={state} extraClass={state ? `fullslider` : ``} />
      ) : tab === "pres1" ? (
        <PresSlider state={state} extraClass={state ? `fullslider` : ``} />
      ) : tab==="pres4" ? <Visualization state={state} extraClass={state ? `visualsSlider` : ``} /> : (
        ""
      )}
    </div>
  );
}
export default PresentationsContainer;
