import React, { useState } from "react";
import * as styles from "./VideoSection.module.scss";
import { BsFillPlayFill } from "react-icons/bs";
function VideoSection({ url, logo, title, desc, bgcolor, image, imglogo,extraClass,children }) {
  const [state, setState] = useState(false);
  return (
    <div style={{ backgroundColor: bgcolor }} className={`${styles.container} ${styles[extraClass]}`}>
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className={styles.image}
      >
        {state ? (
          <video onLoad={false} autoPlay={state ? true : false} controls={true}>
            <source src={url} />{" "}
          </video>
        ) : (
          <div className={styles.icon}>
            <BsFillPlayFill onClick={() => setState(true)} />{" "}
          </div>
        )}
      </div>
      <div className={styles.content}>
        {logo ? logo : <img src={imglogo} alt="logo"/>}
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
        {desc && <p dangerouslySetInnerHTML={{ __html: desc }}></p>}
        {children}
      </div>
    </div>
  );
}
export default VideoSection;
