import React, { useState } from "react";
import VideoSection from "../../atoms/VideoSection/VideoSection";
import * as styles from "./VideosTabs.module.scss";
import LogoVideo1 from "../../../svg/mwlogo.svg";
import libraryimage from "../../../images/wizzy2blury.jpg";
import StudioVideo from "../../molecules/StudioVideo/StudioVideo";
import LogoVideo2 from "../../../svg/connectlogo.svg";
import connectimage from "../../../images/getwizrimage.png";
import LogoVideo3 from "../../../images/getwizrlogo.png";
import FullScreen from "../../../svg/fullscreen.svg";
import ExitFullScreen from "../../../svg/exitfullscreen.svg";
function VideoTabs() {
  const [state, setState] = useState(0);
  const [fullvideo, setFullVideo] = useState(false);
  const toggleFullScreen = () => {
    if (typeof document !== "undefined") {
      if (fullvideo) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
      setFullVideo(!fullvideo);
    }
  };
  return (
    <div
      className={
        fullvideo
          ? `${styles.container} ${styles.fullscreen}`
          : styles.container
      }
    >
      <div className={`${styles.content} ${styles.videos}`}>
        <h1>
         II. VIDEO <span>INTROS</span>
        </h1>
      </div>
      <div className={styles.tabs}>
        <button
          onClick={() => setState(0)}
          className={state === 0 ? styles.active : ""}
        >
          Vision
        </button>
        <button
          onClick={() => setState(1)}
          className={state === 1 ? styles.active : ""}
        >
          Library & Studio
        </button>
        <button
          onClick={() => setState(2)}
          className={state === 2 ? styles.active : ""}
        >
          WiZR Connect
        </button>
        <button
          onClick={() => setState(3)}
          className={state === 3 ? styles.active : ""}
        >
          Future of work
        </button>
        <button className={styles.toggle} onClick={toggleFullScreen}>
          {fullvideo ? <ExitFullScreen /> : <FullScreen />}
        </button>
      </div>
      {state === 0 && (
        <VideoSection
          extraClass={fullvideo ? "fullscreen" : ``}
          url={
            "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1670960421/videos/Movie_1_Final_Video_Audio_Boost_fp5x8p.mp4"
          }
          bgcolor={"#ED7400"}
          logo={<LogoVideo1 />}
          title={"MASTER WiZR Vision"}
          image={libraryimage}
          desc={`Our professional lives are increasingly digital. Using an All-in-One Approach, we are imagining new ways to connect, work, and grow professionally. <br/> <br/> Our vision is to create the ultimate workspace ecosystem.`}
        />
      )}
      {state === 1 && (
        <StudioVideo extraClass={fullvideo ? "fullscreen" : ``} />
      )}
      {state === 2 && (
        <VideoSection
          extraClass={fullvideo ? "fullscreen" : ``}
          url={
            "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1670960914/videos/Movie_3_Final_Audio_Boost_wn8idr.mp4"
          }
          bgcolor={"#3A4B6B"}
          logo={<LogoVideo2 />}
          title={"WiZR Connect"}
          image={connectimage}
          desc={`We are only at the beginning of how we do video conferencing. We're reinventing what a video conferencing system can do, look like, and how it's used. We're making "virtual better than real" `}
        />
      )}
      {state === 3 && (
        <VideoSection
          extraClass={fullvideo ? "fullscreen" : ``}
          url={
            "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1670961191/videos/Movie_4_Updated_Visuals_Final_ysuppv.mp4"
          }
          bgcolor={"#2F6054"}
          imglogo={LogoVideo3}
          title={"The Future of Work"}
          image={
            "https://res.cloudinary.com/dsg2ktuqk/image/upload/c_scale,w_1280,f_auto/v1635982926/meetingbackgrounds/1635982916723-Angle%202%20Green.jpg.jpg"
          }
          desc={`We keep innovating and plan to expand existing modules with more content and create additional product modules. <br/> <br/>
         Here is a sneak peak into developments coming throughout 2023 - creating the ultimate all-in-one workspace solution.`}
        />
      )}
    </div>
  );
}
export default VideoTabs;
