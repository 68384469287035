import React from "react";
import * as styles from "./DemoSectionX.module.scss";
import image from "../../../images/oldMW.png";
function DemoSectionX() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p>
          <b>
            Welcome to a new range of striking communication tools. Offering new
            revolutionizing ways of organizing, presenting and sharing material
            you care about. We are here to guide you.
          </b>{" "}
        </p>
      </div>
      <img src={image} alt="demo"/>
    </div>
  );
}
export default DemoSectionX;
