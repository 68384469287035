// extracted by mini-css-extract-plugin
export var active = "PresentationsContainer-module--active--2e179";
export var arrow = "PresentationsContainer-module--arrow--08182";
export var container = "PresentationsContainer-module--container--2aefb";
export var fullSection = "PresentationsContainer-module--fullSection--f0e2f";
export var left = "PresentationsContainer-module--left--2194b";
export var navigate = "PresentationsContainer-module--navigate--58571";
export var right = "PresentationsContainer-module--right--d8fc6";
export var slider = "PresentationsContainer-module--slider--97de8";
export var tabs = "PresentationsContainer-module--tabs--3e310";
export var toggle = "PresentationsContainer-module--toggle--d9a0f";