import React, { useState } from "react";
import * as styles from "./ManualsSection.module.scss";
import { Link } from "gatsby";
import { manualsdata, videosdata } from "../../../constants/manualsData";
import {FaPlay} from "react-icons/fa";
function ManualsSection({ slicenumber,title,desc }) {
  const [state, setState] = useState(true);
  const data = state ? videosdata : manualsdata;
  return (
    <div className={styles.manuals}>
      <div className={styles.top}>
      {title ? <h1 className={styles.trialHeading}>Elevate Your Content to the Next Level</h1> :   <h1>I. Visual Tour</h1>}
      {desc ? <p className={styles.trialDesc}>During your trial, you'll have full access to our cutting-edge video solution, WiZR Connect, as well as our innovative Library and Studio.<br/>  <span>WiZRX is coming soon.</span> For more info check out videos/manuals below, or the web.</p> :        <p>A visual scroll through product modules  </p> }
      </div>
      <div className={styles.main}>
        <div className={styles.buttons}>
          {slicenumber !== 2 ? (
            <>
              <button
                onClick={() => setState(true)}
                className={state ? styles.active : ``}
              >
                Videos
              </button>
              <span>/</span>
              <button
                onClick={() => setState(false)}
                className={!state ? styles.active : ``}
              >
               Manuals
              </button>
            </>
          ) : (
            <button onClick={() => setState(false)}>Manuals</button>
          )}
        </div>
        <div className={styles.container}>
          {data.slice(0, slicenumber).map((item, i) => {
            return (
              <Link to={item.link}>
                <div key={i} className={`${styles.item} ${styles[item.class]}`}>
                  <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${item.image})` }}
                  >{state && <button className={styles.icon}><FaPlay/></button> }</div>
                  <h2>{item.title}</h2>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default ManualsSection;
