import React from "react";
import * as styles from "./PoweredBanner.module.scss";

function PoweredBanner() {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
      </div>
        <div className={styles.content}>
          <h1>Elevate how You Communicate </h1>
          <p className={styles.sneak}>Powered by MASTER WiZR</p>
        </div>
    </div>
  );
}
export default PoweredBanner;
