import React, { useState } from "react";
import * as styles from "./StudioVideo.module.scss";
import {BsFillPlayFill} from "react-icons/bs";
import Librarylogo from "../../../svg/librarylogo.svg";
import Studiologo from "../../../svg/studio.svg";

function StudioVideo({extraClass}){
    const[state,setState]=useState(false);
    return(
        <div className={`${styles.container} ${styles[extraClass]}`}>

            <div className={styles.image}>
           {state ?  <video onLoad={false} autoPlay={state?true:false} controls={true} >
            <source onLoad={""}  src={"https://res.cloudinary.com/dsg2ktuqk/video/upload/v1670960477/videos/Movie_2_Final_Audio_Update_bkznmf.mp4"}  /> </video>:
             <div className={styles.icon}><BsFillPlayFill onClick={()=>setState(true)}/> </div>}
            </div>
            <div className={styles.content}>
                <div className={styles.logos}>
                 <Librarylogo />
                <div className={styles.studiosvg}>
                <Studiologo />
                </div>
                </div>
                <h1>Library and Studio</h1>
                <p>Our core products are the Library and Studio. Library takes streaming to the next level by allowing you to easily access the content you care about. Studio allows you to customize content for any meeting type.</p>
            </div>
        </div>
    );
}
export default StudioVideo;