import * as styles from "./PresSlider.module.scss";
import React, { useState } from "react";
import Slider from "react-slick";
import ArrowRight from "../../../svg/icon-arrow-right.svg";
import ArrowLeft from "../../../svg/icon-arrow-left.svg";
import { connectData } from "../../../constants/magazinePres";

function PresSoftware({extraClass}){
    const [activeSlide, setActiveSlide] = useState(0);
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow:<div><ArrowLeft/></div>,
        nextArrow:<div><ArrowRight /></div>,
        fade: true,
        accessibility: true,
        fade: false,
        accessibility: true,
        speed:0,
        afterChange: current => setActiveSlide(current)
      };
    return(
        <div  className={`${styles.slider} ${styles[extraClass]}`}>
        <Slider  focusOnSelect={true} {...settings}>
          {connectData.map((item) => {
            return <img src={item} alt="slide"/>;
          })}
        </Slider>
        <div className={styles.btns}>
          <p className={styles.text}>{activeSlide + 1} / {connectData?.length} </p>
        </div>
      </div>
    );
}
export default PresSoftware;