// extracted by mini-css-extract-plugin
export var bgShadow = "StyledButton-module--bgShadow--77666";
export var bigBtn = "StyledButton-module--bigBtn--61b9c";
export var blackTxt = "StyledButton-module--blackTxt--02a34";
export var bluebtn = "StyledButton-module--bluebtn--8a5ad";
export var bluebtn2 = "StyledButton-module--bluebtn2--da07f";
export var borderbtn = "StyledButton-module--borderbtn--40c22";
export var getStarted = "StyledButton-module--getStarted--23c4c";
export var graybtn = "StyledButton-module--graybtn--f6f3b";
export var headerBtn = "StyledButton-module--headerBtn--6b9e9";
export var licenseBtn = "StyledButton-module--licenseBtn--d8b93";
export var nobgLink = "StyledButton-module--nobgLink--dba73";
export var orangeGray = "StyledButton-module--orangeGray--836b2";
export var purplebtn = "StyledButton-module--purplebtn--d9937";
export var slide1 = "StyledButton-module--slide1--b01d7";
export var styledLink = "StyledButton-module--styled-link--aa8f8";
export var vdbtn = "StyledButton-module--vdbtn--b38d4";
export var whiteBtn = "StyledButton-module--white-btn--bbdcc";
export var whiteBtn1 = "StyledButton-module--white-btn1--853c0";
export var whiteLink = "StyledButton-module--whiteLink--81744";