// extracted by mini-css-extract-plugin
export var Library = "ManualsSection-module--Library--ebc29";
export var Studio = "ManualsSection-module--Studio--b02be";
export var active = "ManualsSection-module--active--34e86";
export var buttons = "ManualsSection-module--buttons--28db8";
export var container = "ManualsSection-module--container--2d895";
export var icon = "ManualsSection-module--icon--bdb9c";
export var image = "ManualsSection-module--image--d6307";
export var item = "ManualsSection-module--item--2995c";
export var main = "ManualsSection-module--main--ce628";
export var manuals = "ManualsSection-module--manuals--edb57";
export var top = "ManualsSection-module--top--411ba";
export var trialDesc = "ManualsSection-module--trialDesc--5f98c";
export var trialHeading = "ManualsSection-module--trialHeading--e12de";
export var wizrconnect = "ManualsSection-module--wizrconnect--52e73";
export var wizrx = "ManualsSection-module--wizrx--59aae";